<template>
	{{ stream_width }} x {{ stream_height }}
	<video ref="video" id="video" width="600" height="400" autoplay></video>
	<button id="snap" @click="snapPhoto">Snap Photo</button>
	<canvas ref="canvas" id="canvas" width="600" height="400"></canvas>
	<div id="thewrap">
		<!-- <input type="file" accept="image/*;capture=camera" /> -->
		<!-- <input type="file" accept="image/*" capture="user" /> -->
	</div>
	<a href="https://davidwalsh.name/browser-camera">article</a>
	https://stackoverflow.com/questions/36797514/video-canvas-image-at-a-specific-resolution
</template>

<script>
export default {
	name: 'TheSandbox2',
	components: {},
	data() {
		return {
			stream_width: null,
			stream_height: null,
		}
	},
	mounted() {
		// Grab elements, create settings, etc.
		const video = this.$refs.video
		console.log(video.videoHeight, video.videoWidth)

		// Get access to the camera!
		if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
			// Not adding `{ audio: true }` since we only want video now
			navigator.mediaDevices.getUserMedia({ video: true }).then(function(stream) {
				//video.src = window.URL.createObjectURL(stream);
				video.srcObject = stream
				video.play()
			})
		}

		this.getCameraResolution()
	},
	methods: {
		snapPhoto() {
			this.$refs.canvas.getContext('2d').drawImage(this.$refs.video, 0, 0, 600, 400)
		},
		async getCameraResolution() {
			// suppose we require a full HD video
			let constraints = {
				// audio: false,
				video: {
					width: { ideal: 1920 },
					height: { ideal: 1080 },
				},
			}
			let stream = await navigator.mediaDevices.getUserMedia(constraints)

			let stream_settings = stream.getVideoTracks()[0].getSettings()

			// actual width & height of the camera video
			this.stream_width = stream_settings.width
			this.stream_height = stream_settings.height

			console.log('Width: ' + this.stream_width + 'px')
			console.log('Height: ' + this.stream_height + 'px')
		},
	},
}
</script>

<style scoped lang="scss">
#video,
#canvas {
	width: 100vw;
	height: 100vh;
	width: 6rem;
	height: 4rem;
}
</style>
